@import 'variables';

.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: $grayPale;
    opacity: 1;
}
