@import 'mixins';

@mixin inputStyles {
    line-height: 1;
    font-size: 16px; // font-size of minimum 16px prevents iOS and Android from zooming the page content when a keyboard is displayed
    color: var(--elements-inputs-color, #888);
    padding: 8px 8px 4px 8px !important;
    border: 1px rgba(0, 0, 0, 0.28) solid;
    outline: none;

    &.invalid {
        border-color: red;
    }

    &::placeholder {
        opacity: 0.52;
    }
}

.inputs {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    .input-element {
        flex-grow: 0;
        margin-right: 20px;
        margin-bottom: 2px;
        input {
            @include inputStyles;
        }
        .placeholder {
            font-size: 15px;
            color: #575757;
            margin-top: 10px;
            margin-bottom: 2px;
            margin-left: 2px;
            padding-top: 0;
        }
        ::ng-deep input {
            @include inputStyles;
        }
        &.has-suffix {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            .input-suffix {
                padding-left: 8px;
                flex-grow: 1;
                p {
                    margin-bottom: 0;
                    color: var(--typography-body-color);
                    size: 11px;
                }
            }
        }
        &.ok {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            padding-left: 20px;
            position: relative;
            margin-bottom: 0;
            padding-bottom: 0;
            &:after {
                position: absolute;
                content: '';
                top: 0;
                bottom: 15px;
                left: 0;
                width: 2px;
                background-color: #efefef;
            }
            ::ng-deep button {
                padding: var(--elements-buttons-general-padding, 8px);
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}
