/*
    Set any App specific values of the defualt variables here, before the import of variables file. 
    Example:
    $fontFamily: Roboto, 'Helvetica Neue', sans-serif;
*/

@import 'variables';

* {
    font-family: $fontFamily;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4rem;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 2rem;
    line-height: 2.2rem;
    color: $primary;
}

h2 {
    font-size: 1.8rem;
    line-height: 2rem;
}

h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
}

h4 {
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.text-align-left {
    text-align: left;
}
