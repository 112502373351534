/* imports only. styles should go into a file within ./scss */
@import 'scss/typography';

// vendor
@import '~@angular/material/prebuilt-themes/indigo-pink.css'; //todo fix this

// our custom global styles
@import 'scss/global';
@import 'scss/split';
@import 'scss/forms';

// feature styles
@import '../../../libs/member-direct/member-direct-lib/src/lib/styles';
@import '../../../libs/shared/loading/src/lib/styles';
