@import 'variables';

.member-direct-page {
    padding-bottom: 30px;

    .hero {
        width: 100%;
        height: 300px;
        background-size: cover !important;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    .raised-panel {
        background-color: $white;
        box-shadow: $boxShadowLight;
        max-width: 600px;
        text-align: center;
        border: 1px solid #dee2e5;

        .actions {
            margin-top: calc(#{$gutter} * 2);
        }
    }
}
