body,
html {
    margin: 0;
    padding: 0;
}

body {
    background-color: #ffffff;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

input {
    font-size: initial; // prevents iOS and Android from zooming the page content when a keyboard is displayed
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}
